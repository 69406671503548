<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <a-spin size="large" v-bind="loadingProps" class="full-width full-height">
        <router-view></router-view>
      </a-spin>
    </a-config-provider>
  </div>
</template>
<script>
import Vue from "vue";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  data() {
    return {
      loadingProps: {
        spinning: false,
      },
      locale: zhCN,
    };
  },
  computed: {},
  beforeCreate() {
    Vue.prototype.$app = this;
  },
  methods: {},
};
</script>
<style lang="less">
#app {
  width: 100vw;
  height: 100vh;
}
#app .ant-spin-container {
  width: 100%;
  height: 100%;
}
</style>
