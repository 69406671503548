import axios from "axios";
import Vue from "vue";
import router from "@/router";
import store from "@/store";
import { getToken } from "@/utils/auth";
import errorCode from "./errorCode"; // 状态码
import NProgress from "nprogress"; // Progress 进度条
import "nprogress/nprogress.css"; // Progress 进度条样式
import MD5 from "js-md5";
import { sign } from "ljzn-sass-sign";

// 是否显示重新登录
let isReloginShow;
let vm = new Vue();
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.VUE_APP_SERVE
      : process.env.VUE_APP_BASE_API,
  timeout: 5000,
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    NProgress.start();
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;

    if (getToken() && !isToken) {
      // 让每个请求携带自定义token 请根据实际情况自行修改
      config.headers["Authorization"] = "Bearer " + getToken();
    }

    // 签名
    const resObj = sign(MD5, config);
    const keyArr = Object.keys(resObj);
    keyArr.forEach((key) => {
      config.headers[key] = resObj[key];
    });
    return config;
  },
  (error) => {
    NProgress.done();
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    NProgress.done();

    const res = response.data;
    const code = res.err_code || 200;
    // 错误信息
    // const message = errorCode[code] || res.err_msg || errorCode['default']
    const message = res.err_msg || errorCode["default"];
    if (code === 4001) {
      if (!isReloginShow) {
        isReloginShow = true;
        vm.$confirm({
          title: "系统提示",
          content: "登录状态已过期，您可以继续留在该页面，或者重新登录",
          okText: "确认",
          cancelText: "取消",
          onOk() {
            isReloginShow = false;
            store.dispatch("logout").then(() => {
              router.replace("/login");
            });
          },
          onCancel() {
            isReloginShow = false;
          },
        });
      }
      return Promise.reject("无效的会话，或者会话已过期，请重新登录。");
    } else if (code === 4005) {
      messageError(message);
      return Promise.reject(new Error(message));
    } else if (!res.err_code) {
      // console.log("导出", response);
      return response;
    } else if (code !== 200) {
      messageError(message);
      return Promise.reject(new Error(message));
    } else {
      return res;
    }
  },
  (error) => {
    NProgress.done();
    console.log("err" + error); // for debug
    let { message } = error;
    if (message === "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    messageError(error.message);

    return Promise.reject(error);
  }
);

// 处理同一时间只会出现一个错误提示
let flag = false;
const messageError = (RtnMsg) => {
  if (!flag) {
    flag = true;
    vm.$message.error(RtnMsg);
    setTimeout(() => {
      flag = false;
    }, 800);
  }
};

export default service;
