import request from "@utils/request";

export function getCarNumber(params) {
  return request({
    url: "screen/car_number",
    method: "get",
    params: params,
  });
}

export function getCustomerNumber() {
  return request({
    url: "screen/customer_number",
    method: "get",
  });
}
export function getWorkTime() {
  return request({
    url: "screen/cumulative_work_time",
    method: "get",
  });
}

export function getCarLocation() {
  return request({
    url: "screen/car_location",
    method: "get",
  });
}
//http://127.0.0.1:8888/v1/screen/customer_number_with_city

export function getUnit() {
  return request({
    url: "screen/customer_location",
    method: "get",
  });
}

export function getCityNumber(params) {
  return request({
    url: "screen/customer_number_with_city",
    method: "get",
    params: params,
  });
}
export function getCityCarNumber(params) {
  return request({
    url: "screen/car_number_with_city",
    method: "get",
    params: params,
  });
}

export function getCarWeek(params) {
  return request({
    url: "screen/agv_car_week_duration",
    // http://127.0.0.1:8888/v1/screen/agv_car_week_duration
    method: "get",
    params: params,
  });
}
