/* eslint-disable no-unused-vars */
import Vue from "vue";
import { getToken, setToken, removeToken } from "@/utils/auth";
import storage from "@/utils/storage";
// const vm = new Vue();
const user = {
  state: {
    token: getToken(),
    systemName: "sass云平台管理端",
    userInfo: storage.getItem("userInfoAdmin") || {},
    locale: storage.getItem("admin-Language") || "cn",
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo;
      storage.setItem("userInfoAdmin", userInfo);
    },
    SET_LOCALE: (state, value) => {
      state.locale = value;
      storage.setItem("admin-Language", value);
    },
  },

  actions: {
    // 登录
    login({ commit }, userInfo) {
      // commit("SET_TOKEN", "哈哈");
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.login
          .login(userInfo)
          .then((res) => {
            const { err_data } = res;
            setToken(err_data.access_token);
            commit("SET_TOKEN", err_data.access_token);
            commit("SET_USERINFO", err_data);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 退出
    // 退出
    logout({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit("SET_TOKEN", "");
        commit("SET_USERINFO", {});
        storage.clearItem("userInfoAdmin");
        removeToken(); // 必须先移除token
        resolve();
      });
    },
  },
};

export default user;
