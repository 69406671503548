/**
 * storage
 */
export default {
  setItem(key, val) {
    window.localStorage.setItem(key, JSON.stringify(val));
  },
  getItem(key) {
    return JSON.parse(window.localStorage.getItem(key));
  },
  /* getStorage(key) {
    return JSON.parse(window.localStorage.getItem(key) || "{}");
  }, */
  clearItem(key) {
    window.localStorage.setItem(key, "{}");
  },
  clearAll() {
    window.localStorage.clear();
  },
};
