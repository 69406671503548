import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./BaseComponent/lazy_useUI";
import "@utils/rem";
import api from "./api/index";
import "@/styles/index.less";

// Vue.prototype.$echarts = echarts
import * as echarts from "echarts";
import "echarts-gl"; // 3d图表库

Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;
Vue.prototype.$api = api;
Vue.prototype.CODE_OK = 200;
Vue.prototype.$setLoading = function (props) {
  if (typeof props === "boolean")
    props = {
      spinning: props,
    };
  if (Object.prototype.toString.call(props) !== "[object Object]") props = {};
  this.$app.loadingProps = {
    tip: "加载中...",
    ...props,
  };
};
new Vue({
  router,
  store,

  render: (h) => h(App),
}).$mount("#app");
