import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";

const modules = {};
const files = require.context("./modules", false, /\.(js|ts)$/);
files.keys().forEach((key) => {
  const name = key.substring(key.lastIndexOf("/") + 1, key.length - 3);
  modules[name] = files(key).default;
});

Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
  getters,
});

export default store;
